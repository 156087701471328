<template>
  <app-modal-webhook-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :webhookEventLog="selectWebhookEventLog"
    @refresh="fetchWebhookDetailsIndex"
  />
  <app-table
    :apiResponse="apiPaginationWebhookLogResponse?.data ?? []"
    @on-item-click="onItemClick"
    :loading="loading"
    :showEmptyButton="false"
    :filters="[
      {
        key: 'filter[uuid]',
        type: 'text',
        placeholder: $t('integration.webhook_event.uuid'),
        value: '',
      },
      {
        key: 'filter[url]',
        type: 'text',
        placeholder: $t('integration.webhook_event.url'),
        value: '',
      },
      {
        key: 'filter[status]',
        type: 'select',
        placeholder: $t('integration.webhook_event.status'),
        items: [
          {
            id: WEBHOOK_EVENT_LOG_STATUS.CREATED,
            name: WEBHOOK_EVENT_LOG_STATUS.CREATED,
          },
          {
            id: WEBHOOK_EVENT_LOG_STATUS.SUCCEED,
            name: WEBHOOK_EVENT_LOG_STATUS.SUCCEED,
          },
          {
            id: WEBHOOK_EVENT_LOG_STATUS.FAILED,
            name: WEBHOOK_EVENT_LOG_STATUS.FAILED,
          },
        ],
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: 'Date',
        value: '',
      },
    ]"
    @params-changed="paramsChanged"
  >
    <template v-slot:title>
      <div class="flex space-x-3">
        <app-icon-outline
          @click="goToBack"
          name="ArrowLeftIcon"
          class="w-5 cursor-pointer"
        />
        <p class="font-medium">
          {{ $t("integration.webhook_event.webhook_event_logs") }}
          {{ webhook?.name ? ": " + webhook?.name : "" }}
        </p>
      </div>
    </template>
    <template v-slot:header>
      <th>{{ $t("integration.webhook_event.uuid") }}</th>
      <th>{{ $t("integration.webhook_event.status") }}</th>
      <th>{{ $t("integration.webhook_event.url") }}</th>
      <th>{{ $t("integration.webhook_event.no_of_attempt") }}</th>
      <th>{{ $t("integration.webhook_event.last_attempt_at") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td>
        <p>{{ slotData.model.uuid }}</p>
      </td>
      <td>
        <app-badge
          :status="slotData.model.status == 'succeed' ? 'success' : 'light'"
          class="w-16 text-xs"
        >
          {{ slotData.model.status }}
        </app-badge>
      </td>
      <td>
        <p class="truncate w-40">{{ slotData.model.url }}</p>
      </td>
      <td>
        {{ slotData.model.attempt }}
      </td>
      <td>
        <p>
          {{
            slotData.model?.updated_at
              ? $moment(slotData.model.updated_at).format("DD MMMM YYYY")
              : "-"
          }}
        </p>
      </td>
    </template>
  </app-table>
</template>
<script>
import WEBHOOK_EVENT_LOG_STATUS from "@/utils/const/webhook_event_log_status";

export default {
  data() {
    return {
      showModalDetail: false,
      selectWebhookEventLog: null,
      queryParams: "",
      emptyErrors: {},
      WEBHOOK_EVENT_LOG_STATUS: WEBHOOK_EVENT_LOG_STATUS,
    };
  },

  computed: {
    apiPaginationWebhookLogResponse() {
      return this.$store.getters["integrationStore/apiPaginationWebhookLogResponse"];
    },
    integration() {
      return this.$store.getters["integrationStore/integration"];
    },
    loading() {
      return this.$store.getters["integrationStore/loading"];
    },
    webhook() {
      return this.$store.getters["integrationStore/webhook"];
    },
    errors() {
      return this.$store.getters["integrationStore/errors"];
    },
    message() {
      return this.$store.getters["integrationStore/message"];
    },
  },

  mounted() {
    this.fetchWebhookDetailsIndex();
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchWebhookDetailsIndex();
    },
    async fetchWebhookDetailsIndex() {
      let index = await this.$store.dispatch("integrationStore/webhookDetailsIndex", {
        business_id: this.$route.params.business_id,
        integration_id: this.$route.params.integration_id,
        webhook_event_id: this.$route.params.webhook_event_id,
        queryParams: this.queryParams,
      });

      index
    },
    onItemClick(webhookEventLog) {
      this.showModalDetail = !this.showModalDetail;
      this.selectWebhookEventLog = webhookEventLog;
    },
    resetState() {
      this.$store.commit("integrationStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
    goToBack() {
      this.$router.go(-1);
    },
  },
};
</script>